<template lang="pug">
  div
    el-alert(
      title="Bu bölümü görüntüleme yetkiniz yok"
      v-if="!can('guides.list')"
      :closable="false"
      type="error"
      show-icon
      center
    )
    el-card(body-style="padding: 0")
      div(slot="header")
        el-form.pt-10(:inline="true" :model="filters")
          el-form-item
            el-button(
              type="primary"
              :plain="true"
              @click="create"
              icon="el-icon-plus"
              :disabled="!can('guides.create')"
            ) Oluştur
          el-form-item(label="Acenta")
            el-select(
              v-model="filters.company_id"
              placeholder="Seçiniz"
              @change="fetch"
              clearable
            )
              el-option(v-for="t in agencies" :key="t.id" :label="t.name" :value="t.id")
          el-form-item(label="Durumu")
            el-select(
              v-model="filters.active"
              placeholder="Durumu seçiniz"
              @change="fetch"
            )
              el-option(label="Tümü" :value="null")
              el-option(label="Sadece aktif" :value="1")
              el-option(label="Sadece pasif" :value="0")
      el-input(placeholder="Hanutçu ara" v-model="search" v-if="can('guides.list')")
        i.el-input__icon.el-icon-search(slot="suffix")
      el-table(
        :data="filteredUsers"
        @row-dblclick="edit"
        :stripe="true"
        v-loading="fetching"
        size="mini"
        :height="$store.state.windowHeight-190"
        v-if="can('guides.list')"
      )
        el-table-column(type="index" width="35px" align="right" class-name="text-muted")
        el-table-column(prop="active" label="Aktif" align="center" width="80px" sortable)
          template(slot-scope="props")
            el-switch(
              v-if="props.hasOwnProperty('row')"
              :value="props.row.active"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="activate(props.row, $event)"
            )
        el-table-column(prop="name" label="Ad, soyad" sortable)
        el-table-column(prop="company.name" label="Acenta" sortable)
        el-table-column(prop="phone" label="Telefon" sortable)
        el-table-column(label="KOMİSYONLAR" align="center" class-name="text-primary")
          el-table-column(class-name="text-primary" align="right" width="125" v-for="c in categories" :key="c.id" prop="commissions" :label="c.name")
            template(v-slot="props") {{ getCommission(props.row.id, c.id) }}
        el-table-column(prop="id" label="İşlem" align="center")
          template(v-slot="props")
            el-button-group
              el-button.micro(plain @click="edit(props.row)"): span.el-icon-edit
              el-button.micro(plain @click="remove(props.row.id)"): span.el-icon-delete.text-danger
    el-drawer(
      :visible.sync="modal"
      :title="user.name"
      size="600px"
    )
      el-form.p-20(ref="form" :model="user" label-width="170px")
        el-form-item(label="Rehber adı")
          el-input(v-model="user.name")
        el-form-item(label="Acenta")
          el-select(
            v-model="user.company_id"
            placeholder="Seçiniz"
            style="width: 100%"
          )
            el-option(v-for="t in agencies" :key="t.id" :label="t.name" :value="t.id")
        el-form-item(label="Telefon numarası")
          el-input(v-model="user.phone")
        el-form-item(label="Aktif")
          el-switch(v-model="user.active" active-color="#13ce66" inactive-color="#ff4949")
        el-form-item(label="Devir")
          el-input(type="number" v-model.number="user.account_transfer")
      el-divider: span.text-primary Komisyon oranları, %
      entity-commissions(v-model="user.commissions" :key="`guide-${user.id}`")
      center.pt-20: el-button(@click="save" :loading="saving" icon="el-icon-check") Kaydet
</template>
<script>
import get from 'lodash/get'
import ProductCategory from '@/models/ProductCategory'
import User from '@/models/User'
import Company from '@/models/Company'
import EntityCommissions from '@/components/EntityCommissions.vue'
export default {
  name: 'dealers',
  components: { EntityCommissions },
  data(){
    return {
      user: { name: '', commissions: [] },
      users: [],
      agencies: [],
      categories: [],
      modal: false,
      fetching: false,
      saving: false,
      search: '',
      filters: {
        active: 1,
        company_id: null
      }
    }
  },
  async mounted () {
    if(this.can('guides.list')){
      this.categories = await ProductCategory.get()
      await this.fetch()
      this.agencies = await Company.where('type', 'agency').get()
    }
  },
  methods: {
    async fetch(excel){
      try{
        this.fetching = true
        this.users = await User.include('company', 'commissions')
          .whereIn('roles', ['guide'])
          .where('active', this.filters.active)
          .where('company_id', this.filters.company_id)
          .get()
        this.fetching = false
      }catch({ response }){
        this.fetching = false
        this.$message.error(response.data.message)
      }
    },
    create(){
      this.user = new User({
        name: '',
        phone: '',
        active: true,
        account_transfer: 0,
        roles: ['guide'],
        commissions: []
      })
      this.modal = true
    },
    async edit(row){
      try{
        this.user = await User.include('commissions').find(row.id)
        this.modal = true
      }catch({ response }){
        this.$message.error(response.data.message)
      }
    },
    async save(){
      try{
        this.saving = true
        await this.user.save()
        this.saving = false
        this.modal = false
        await this.fetch()
      }catch(e){
        this.saving = false
      }
    },
    async remove(id){
      await this.$confirm('Kayıt silinmesine onay veriniz', 'Uyarı', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Vazgeç',
        type: 'warning',
        center: true
      })
      try{
        let { data } = await window.axios.delete('/users/' + id)
        this.fetch(false)
        if(data.deleted){
          this.$message({
            type: 'success',
            message: 'Kayıt silinmiştir',
            center: true,
            closable: true
          })
        }
      }catch(e){
        this.$message({
          type: 'error',
          message: 'Üzerine satış, rezervasyon veya ödeme tanımlanmıştır. Kayıt silinemez',
          center: true,
          closable: false
        })
      }
    },
    async activate(user, status){
      try{
        user.active = status
        await axios.post(`users/activate/${user.id}`)
        this.$message.success(status === true ? 'Kullanıcı aktif' : 'Kullanıcı pasif')
      }catch({ response }){
        this.saving = false
        this.$message.error(response.data.message)
      }
    },
    getCommission(id, category_id){
      let model = this.users.find(a => a.id === id)
      let comm = model.commissions.find(c => c.category_id === category_id)
      return get(comm, 'foreign', 0)
    }
  },
  computed: {
    saveable: function(){
      return this.user.name.length > 0
    },
    filteredUsers: function(){
      return this.users.filter(u => u.name.toLowerCase().includes(this.search.toLowerCase()))
    }
  }
}
</script>
